




















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import FAQS from '@/pages/faqs'
import OverviewBanner from '@/components/Overview/OverviewBanner.vue'

@Component({
  components: {
    OverviewBanner,
  },
})
export default class HomeInfoCard extends Vue {
  @Prop(Boolean) isColumn!: boolean
  @Getter defaultAudioCollection!: object
  @Action showCreatePodcast!: any
  @Action showImportPodcast!: any

  get faqs() {
    return FAQS
  }

  handleUploadAudio() {
    this.$store.commit('setYoutubeModal', 'AUDIO')
    this.$store.commit('setCurrentModal', 'importAndUpload')
  }

  handleLearnTags() {
    this.$router.push('/tags')
  }
}
