





























import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import TheMainPage from '@/components/Common/TheMainPage.vue'
import TheHeader from '@/components/Common/TheHeader.vue'
import TheSideNavigation from '@/components/Common/TheSideNavigation.vue'
import LazyLoader from '@/mixins/LazyLoader'
import TheTitle from '@/components/Common/TheTitle.vue'
import AdoriTag from '@/components/Tags/AdoriTag.vue'
import TheTagsGrid from '@/components/Tags/TheTagsGrid.vue'
import TheTagCollections from '@/components/Tags/Collections/TheTagCollections.vue'
import OverviewBanner from '@/components/Overview/OverviewBanner.vue'
import TagsInfoCard from '@/components/Tags/EmptyState/TagsInfoCard.vue'
import banner_no_tags_new from '@/assets/no-tags-new.svg'
import HomeBanner from '@/components/Home/HomeBanner.vue'
import HomeInfoCard from '@/components/Home/HomeInfoCard.vue'

@Component({
  components: {
    TheMainPage,
    TheHeader,
    TheSideNavigation,
    TheTitle,
    AdoriTag,
    TheTagsGrid,
    TheTagCollections,
    OverviewBanner,
    TagsInfoCard,
    HomeBanner,
    HomeInfoCard,
  },
})
export default class ViewTags extends Vue {
  @Getter networkType!: string
  @Getter hasLoadedUser!: boolean
  @Getter tagIds!: string[]
  @Getter windowOrientation!: string

  // Empty state values
  bannerHeaderTags: string = 'Embed actions within audio'
  bannerSubHeaderTags: string = `Compose interactive experiences that surface contextual visuals, links, polls and more without interrupting the audio. Listeners can now engage deeper with the content, and avoid the usual recall and search. Enable direct monetization from within the audio via 1-click donate, affiliate links, and brand offers.`
  bannerImageUrlTags: string = banner_no_tags_new
  bannerLocation: string = 'ALL_TAGS'
  loadingTags: boolean = false
  isTagsGridLoading: boolean = false
  orientation: string = 'LANDSCAPE'

  async created() {
    this.selectCollection('ALL_TAGS')
  }

  get loaders() {
    return new Array(50)
  }

  async mounted() {
    window.scrollTo(0, 0)
    if (this.orientation) {
      await this.setFilter(this.orientation)
    }
  }

  get currentCollection() {
    return this.$store.getters.selectedTagCollection
  }

  get allTags() {
    return this.tagIds
  }

  async selectCollection(collectionName: string) {
    this.$store.dispatch('setSelectedTagCollection', collectionName)
    for (const id of this.$store.getters.tagCollections[collectionName] || []) {
      if (
        !this.$store.getters.allTagsById[id] ||
        (!this.$store.getters.tag(id) && !this.$store.getters.allTagsById[id])
      ) {
        try {
          await this.$store.dispatch('getTag', id)
        } catch (e) {
          this.$store.dispatch('removeTagsFromTagCollection', {
            collectionName: this.currentCollection,
            tagIds: [id],
          })
        }
      }
    }
  }

  createNewTag() {
    this.$store.dispatch('showTagEdit', null)
  }

  async setFilter(value: string) {
    this.orientation = value
  }
  @Watch('windowOrientation')
  onOrientationChange() {
    if (this.windowOrientation && this.windowOrientation !== this.orientation) {
      this.orientation = this.windowOrientation
    }
  }
}
