var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TheMainPage",
    [
      _c("TheHeader"),
      _c(
        "div",
        { staticClass: "flex flex-wrap" },
        [
          _c("TheSideNavigation"),
          _c(
            "div",
            { staticClass: "limiter scrollbar" },
            [
              _c("TheTitle", {
                attrs: {
                  icon: "local_offer",
                  title: "Interactive Tags",
                  large: true,
                },
              }),
              _c(
                "div",
                {
                  staticClass: "flex flex-wrap justify-around mb5",
                  staticStyle: { "padding-top": "4px" },
                },
                [
                  _c("TheTagCollections", {
                    attrs: {
                      currentCollection: _vm.currentCollection,
                      selectCollection: _vm.selectCollection,
                      filter: _vm.orientation,
                      showCreateCollection: "",
                      showAllTags: "",
                    },
                    on: { setFilter: _vm.setFilter },
                  }),
                  _c("TheTagsGrid", {
                    attrs: {
                      currentCollection: _vm.currentCollection,
                      orientation: _vm.orientation,
                      selectCollection: _vm.selectCollection,
                      tagIsSelectable: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }